.content {
    height: 40%;
    width: 50%;
    min-width: 640px;
    min-height: 300px;
    display: flex;
    flex-direction: row;
  }
  .spacer {
    flex: 11.5;
  }
  .fivegWrap {
    flex: 8;
    display: flex;
    flex-direction: column;
  }
  .fiveWrap {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
  .five {
    font-weight: bolder;
    font-size: 6em;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    align-self: flex-end;
    -webkit-text-stroke: 4px rgba(0,0,0,.7);;
    color: rgba(0,0,0,0);
  }
  .gWrap {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
  .g {
    font-weight: bolder;
    font-size: 6em;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    align-self: flex-start;
    -webkit-text-stroke: 4px rgba(0,0,0,.7);
    color: rgba(0,0,0,0);
  }
  .centerBar {
    border-radius: 30px;
    border-width: 4px;
    border-color: rgba(0,0,0,.7);
    border-style: solid;
    flex: 1;
    background: rgba(0,0,0,0);
  }
  .infoWrap {
    flex: 19.5;
    display: flex;
    flex-direction: column;
  }
  .upperi {
    flex: 1;
  }
  .hakron {
    color: rgba(0,0,0,.7);
    font-size: 3.5em;
    margin-top: 0;
    margin-bottom: 0;
  }
  .generation {
    color: rgba(0,0,0,.7);
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 0;
  
  }
  .middlei {
    flex: 1;;
  }
  .middleText {
    color: rgba(0,0,0,.7);
    font-size: 1em;
    margin-top: 0;
    margin-bottom: .5vh;
    margin-left: 3.5vw;
    margin-right: 3.5vw;
  
  }
  .bottomi {
    flex: 0.6;
  }
  .bottomText {
    color: rgba(0,0,0,.7);
    font-size: 1em;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 3.5vw;
    margin-right: 3.5vw;
  }
  .email {
    color: rgba(0,0,0,.7);
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    background: black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footertxt {
    color: white;
    margin: 5px;
}