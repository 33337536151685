.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg, rgb(173, 119, 196), rgb(95, 95, 197), rgb(119, 194, 119), rgb(218, 218, 134), rgb(250, 180, 110), rgb(245, 111, 111));
	background-size: 400% 400%;
	-webkit-animation: gradientBG 60s ease infinite;
	        animation: gradientBG 60s ease infinite;
}
@-webkit-keyframes gradientBG {
	0% {
    transform: skewy(0deg);
    transform: rotatex(0deg);
		background-position: 0% 0%;
	}
	50% {
    transform: skewy(20deg);
    transform: rotatex(20deg);
		background-position: 100% 100%;
	}
	100% {
    transform: skewy(0deg);
    transform: rotatex(0deg);
		background-position: 0% 0%;
	}
}
@keyframes gradientBG {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0% 0%;
	}
}