.mcontent {
    width: 100%;
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mhead {
    flex: 20;
    text-align: center;
}
.mhakron {
    color: rgba(0,0,0,.7);
    font-size: 3em;
    margin-top: 0;
    margin-bottom: 0;
}
.mfiveg {
    font-weight: bolder;
    font-size: 4em;
    margin: 0;
    align-self: flex-end;
    -webkit-text-stroke: 3px rgba(0,0,0,.7);;
    color: rgba(0,0,0,0);
}
.mbar {
    width: 90%;
    border-radius: 10px;
    border-width: 3px;
    border-color: rgba(0,0,0,.7);
    border-style: solid;
    flex: 3;
    background: rgba(0,0,0,0);
    margin-top: 3vh;
    margin-bottom: 3vh;
}
.mupperi {
    width: 85%;
    flex: 42;
}
.mtext {
    color: rgba(0,0,0,.7);
    font-size: 1em;
    margin-top: 0;
    margin-bottom: .5vh;
    margin-left: 3.5vw;
    margin-right: 3.5vw;
}
.mloweri {
    width: 85%;
    flex: 36;
}
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    background: black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footertxt {
    color: white;
    margin: 5px;
}